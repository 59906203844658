import React from "react"
import { useContext } from "react"
import { Litery } from "../../../interfaces/Page"
import { ModalContext } from "../../store/ModalContext"

type Props = {
  data: Litery[]
}

const Workshop: React.FC<Props> = ({ data }): JSX.Element => {
  const context = useContext(ModalContext)

  const getModal = (letter: string) => {
    const found = data?.find(x => {
      return x.letter === letter
    })

    ////console.log(found)

    if (found) {
      return found
    }

    return null
  }

  return (
    <svg width={"100%"} viewBox={`0 0 1226 924`}>
      <image
        href={"/maszyna.jpg"}
        x={0}
        y={0}
        width={1226}
        height={924}
      ></image>

      <g
        onClick={() => {
          context.setModal(getModal("B"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(570 667)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/B.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("C"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(389 667)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/C.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("D"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(343 579)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/D.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("E"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(318 478)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/E.png"}></image>
      </g>

      {/* <g
        onClick={() => {
          context.setModal(getModal("F"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(436 579)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/F.png"}></image>
      </g> */}

      <g
        onClick={() => {
          context.setModal(getModal("H"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(620 579)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/H.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("K"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(802 578)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/K.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("M"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(751 667)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/M.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("N"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(661 667)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/N.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("P"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(961 478)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/P.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("R"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(406 478)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/R.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("S"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(251 579)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/S.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("T"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(499 478)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/T.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("W"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(225 478)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/W.png"}></image>
      </g>

      <g
        onClick={() => {
          context.setModal(getModal("Z"))
        }}
        style={{cursor: 'pointer'}}
        transform={`translate(596 478)`}
      >
        <circle cx={37} cy={37} r={37} fill={"red"}></circle>
        <image x={0} y={0} width={74} height={74} href={"/Z.png"}></image>
      </g>
    </svg>
  )
}

export default Workshop
